@import '../variables.less';

.br-sm {
    border-radius: @br-sm;
}

.br-md {
    border-radius: @br-md;
}

.br-lg {
    border-radius: @br-lg;
}

.br-50 {
    border-radius: 50%;
}

.border-none {
    border: none;
}