@import '../variables.less';

.font-xs {
    font-size: @font-xs;
}

.font-sm {
    font-size: @font-sm !important;
    line-height: @line-height-xlg;
}

.font-md {
    font-size: @font-md;
}

.font-lg {
    font-size: @font-lg;
}

.font-xlg {
    font-size: @font-xlg;
}

.font-xxlg {
    font-size: @font-xxlg;
}