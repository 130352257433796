h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

address {
    font-style: normal;
}