@import '../variables.less';

html {
    // overflow: auto; prevents body-scroll-lock from functioning
}

body.tbd {
    margin: 0;
    padding: 0;
    color: @base-color-second;
    font-family: @base-font-family;
    font-weight: normal;
    font-size: @base-font-size;
    line-height: @base-line-height;
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: @screen-xs-max) {
        font-size: @base-font-size-xs;
    }
}

* {
    box-sizing: border-box;
}

picture {
    display: flex;

    img {
        height: auto;
    }
}


a {
    color: @base-color;
    cursor: pointer;
}

input[type=button] {
    cursor: pointer;
}

a:not(.underline) {
    text-decoration: none;
}

a:not(.btn) {
    position: relative;

    &:after {
        content: '';
        position: absolute;
    }
}

a.link,
a.link-2 {
    &:after {
        opacity: 0;
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        background: #aec6e2;
        bottom: -6px;
        left: 0;
        transition: .085s all ease-in;
    }

    &:hover:after,
    &.active:after {
        opacity: 1;
    }
}

a.link-2 {
    &:after {
        bottom: -4px;
        opacity: 1;
    }

    &:hover:after {
        opacity: 0;
    }
}

h1, .h1,
h2, .h2,
h3, .h3
h4, .h4,
h5, .h5,
h6, .h6,
.h7 {    
    color: @base-color;
}

h1, .h1 {
    font-size: 2.5rem; // 40px
    font-weight: 800;
    line-height: 1.25;
    letter-spacing: -0.075rem;

    @media screen and (min-width: @screen-md-min) {
        font-size: 2.65rem; // 42.5px
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 2.8rem; // 45px 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 3.125rem; // 50px
    }
}

h2, .h2 {
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.8rem;
    letter-spacing: -0.05rem;
        
    @media screen and (min-width: @screen-md-min) {
        font-size: 34px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        line-height: 3.25rem;
        font-size: 36px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 40px;
    }
}

h3, .h3 {
    font-size: 24px;
    font-weight: 800;

    &+p {
        font-size: @font-md;
    }

    @media screen and (min-width: @screen-md-min) {
        font-size: 25.5px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 27px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 30px;
    }
}

h4, .h4 {
    font-size: 20px;

    @media screen and (min-width: @screen-md-min) {
        font-size: 21px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 22.5px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 25px;
    }
}

h5, .h5 {
    font-size: 16px;

    @media screen and (min-width: @screen-md-min) {
        font-size: 17px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 18px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 20px;
    }
}

h6, .h6 {
    font-size: 16px;

    @media screen and (min-width: @screen-md-min) {
        font-size: 17px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 18px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 20px;
    }
}

.h7 {
    font-size: 14px;

    @media screen and (min-width: @screen-md-min) {
        font-size: 15px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 16px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 18px;
    }
}

.text-detail {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
}

p {
    color: @base-color-second;
    line-height: 2rem;
    font-size: 14.5px;

    @media screen and (min-width: @screen-md-min) {
        font-size: 15px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 16px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 18px;
    }

    &.smaller {
        font-size: 13px;

        @media screen and (min-width: @screen-md-min) {
            font-size: 14px; 
        }
        @media screen and (min-width: @screen-lg-min) {
            font-size: 15px; 
        }
        @media screen and (min-width: @screen-xlg-min) {
            font-size: 16px;
        }
    }
}

input,
textarea {
    outline: none;
}

ul li {
    font-size: @font-md;

    a {
        color: @base-color-second;
    }
}

nav a {
    font-size: @font-sm;
}

.max-width-sm {
    max-width: @max-width-sm;
}

.max-width-md {
    max-width: @max-width-md;
}

.max-width-lg {
    max-width: @max-width-lg;
}

.max-width-xlg {
    max-width: @max-width-xlg;
}

.subtitle {
    color: @success;
    font-size: @font-sm;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
}

&.dots {
    position: relative;
    z-index: 1;

    &:before {
        background: url(../../img/bg/grid-dots.png);
        content: '';
        height: 161px;
        left: -70px;
        position: absolute;
        top: 42px;
        width: 161px;
        z-index: -1;
    }
}

.mb6 {
    margin-bottom: 8rem;
}

.base-color {
    color: @base-color;
}

.success {
    color: var(--success);
}

.success-alt-1 {
    color: @color-success-alt-1;
}

.success-alt-5 {
    color: @color-success-alt-5;
}

.success-alt-7 {
    color: @color-success-alt-7;
}

.secondary {
    color: @secondary;
}

.secondary-2 {
    color: @secondary-2;
}

.secondary-3 {
    color: @secondary-3;
}

.bg-secondary {
    background-color: @secondary;
}

.bg-secondary-2 {
    background-color: @secondary-2;
}

.bg-secondary-3 {
    background-color: @secondary-3;
}

.fw-bold {
    font-weight: @fw-bold  !important;
}

.fw-bolder {
    font-weight: @fw-bolder;
}

.bg-svg {
    position: absolute;
    z-index: -1;
}

.caret {
    background: url('../../img/svg/caret.svg');
    display: block;
    height: 1rem;
    width: 1rem;
    background-size: 1rem 1rem;
}

.dropdown-menu {
    left: calc(-560px/3);
    width: calc(560px/1.5);
    margin-left: 50%;
    background: #fff;
    border-radius: @br-sm;
    box-shadow: @box-shadow-sm;
    display: none;
    padding: 1rem;
    position: absolute;
    top: 2rem;
    z-index: 1000;

    &.open {
        display: block;
    }

    &:before {
        background-position: 50% 0;
        border-right: 10px solid transparent;
        border-bottom: 10px solid @default-1;
        border-left: 10px solid transparent;
        border-bottom: 10px solid #fff;
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        height: 0;
        margin-left: -10px;
        top: -9px;
        width: 0;
    }

    li {
        border-radius: @br-sm;
        margin: 0;

        &:hover {
            background-color: @default-1;
        }
    }

    a {
        display: block;
        padding: 0.5rem 1rem;
        position: relative;
    }
}

.text-content {

    h2 {
        margin: 2rem 0 1.5rem 0;
    }

    h3 {
        margin: 1rem 0;
    }

    p {
        font-size: @font-md;
        margin-bottom: 1rem;
    }
}

.list {

    margin: 1rem 0;

    &>ul {
        margin: 1.5rem 0 1.5rem 1.5rem;
    }

    li {
        color: @base-color-second;
        padding-left: 1.25rem;
        position: relative;

        &:before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: @success;
            position: absolute;
            left: 2px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    li:before {
        background-color: @success;
    }

    &.list-secondary li:before {
        background-color: @secondary;
    }

    &.list-warning li:before {
        background-color: var(--warning);
    }

    &.list-secondary-2 li:before {
        background-color: @secondary-2;
    }
}

.badge-label {
    background: var(--success);
    border-radius: 0.5rem;
    color: #fff;
    font-size: @font-sm;
    font-weight: 500;
    padding: 0 1rem;
    text-transform: uppercase;
}

.badge-label-done {
    background: var(--green);
}

.badge-label-warning {
    background: var(--warning);
}

.badge {
    background: var(--success-1);
    border-radius: 2rem;
    color: var(--success);
    font-size: @font-sm;
    padding: 0.2rem 2rem;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
}

.badge-warning {
    background: #fae8ce;
    color: #996f32;
    font-weight: 500;
}

.collapsable {
    display: block;
    opacity: 1;
    transition: all 0.5s ease-in;

    &.collapse {
        opacity: 0;
        display: none;
    }
}

.quotemark {
    opacity: 0.3;
    width: 24px;
}

span[class*="hint--"] {
    border-bottom: 2px dotted @base-color-3;
    padding: 2px 0;

    &:hover {
        border-bottom-color: transparent;
    }
}

span[class*=hint--][aria-label]:after {
    font-weight: 400;
    line-height: 1.05rem;
    z-index: 2;
}

span.hint-multi[class*=hint--][aria-label]:after {
    white-space: pre-wrap;
    width: 160px;
}

.blog {

    h2 {
        margin-top: 4rem;
    }

    p {
        color: #222;
        margin-top: 2rem;
    }

    ul {
        margin-top: 2rem;
        margin-left: 2rem;
    }

    ul li {
        color: #222;
        font-size: 1rem;
        line-height: 2;
        position: relative;

        &:before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 2px;
            background-color: @success;
            position: absolute;
            top: 0.9rem;
            transform: translateY(-50%);
        }

        ul li:before {
            width: 6px;
            height: 6px;
        }
    }

    li>ul {
        margin-top: 1rem;
    }
}

.info {
    font-size: @font-sm;
    font-weight: 500;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
}

.p-info {
    font-size: 15px;
    line-height: 26.3px;
}