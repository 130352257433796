.o-hover {
    &:hover {
        opacity: 0.7;
    }
}

.o1 {
    opacity: 1 !important;
}

.o0 {
    opacity: 0;
}

.o50 {
    opacity: 0.5 !important;
}

.o75 {
    opacity: 0.75 !important;
}

.o85 {
    opacity: 0.85 !important;
}