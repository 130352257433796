.z1 {
    z-index: 1;
}
.z2 {
    z-index: 2;
}
.z100 {
    z-index: 100;
}
.z101 {
    z-index: 101;
}
