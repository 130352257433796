.h-1 {
    height: 1rem;
}
.h-2 {
    height: 2rem;
}
.h-3 {
    height: 3rem;
}
.h-4 {
    height: 4rem;
}
.h-5 {
    height: 5rem;
}

.min-h-100-vh {
    height: 100vh;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.max-w-300 {
    max-width: 300px;
}

.min-w-320 {
    min-width: 320px;
}

.max-w-full {
    max-width: 100%;
}