.square {
    background: @color-square-default;
    border-radius: 12px;
    height: 72px;
    width: 72px;
}

.square.square-lg {
    height: 109px;
    width: 109px;
}

.square-blue {
    background: @color-square-blue;
}

.square-orange {
    background: @color-square-orange;
}

.square-mr {
    margin-right: 0.75rem;
}

.square-mb {
    margin-bottom: 0.75rem;
}
