:root {
    --success-0: #f1f4ff;
    --success-1: #ebf0ff;
    --success-2: #d6e2ff;   
    --success-4: #5c8aff;
    --success: #2766ff;
    --success-6: #0045f5;
    --success-9: #000C29;
    --success-alt: #b6d3e6;

    --primary-4: #16db93;
    --primary: #13c282;
    --primary-6: #0f9563;

    --green: #08ce35;
    --warning: #F0AD4E;
    
    --color-btn-success: var(--success);
    --color-btn-success-hover: var(--success-4);
    --color-btn-success-on: #fff;

    --color-btn-success-alt: var(--success);
    --color-btn-success-alt-hover: var(--success-4);
    --color-btn-success-alt-on: #fff;
}

@base-font-size: 1.125rem;
@base-font-size-xs: 1.125rem;
@base-line-height: 2.25rem;

@base-font-family: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
@spacer: 1rem;

@max-width-sm: 450px;
@max-width-md: 45rem;
@max-width-lg: 1200px;
@max-width-xlg: 1440px;

@base-color: var(--success-9);
@base-color-second: #707a8c;
@base-color-3: #b0b4b8;

@box-shadow-sm: 0px 6px 16px #D8E3F0;
@box-shadow-md: 0px 6px 25px #D8E3F0;
@box-shadow-lg: 0px 6px 40px #D8E3F0;
@box-shadow-xlg: 0 2px 5px #d8e3f0, 0px 6px 40px #D8E3F0;

@form-input-border-color: #C3C3C3;

@default-1: #f2f8ff;
@default-2: #f8f8fd;
@default-3: #e7f1fd;
@default-4: #c0d3ea;
@default-5: #94B4DB;
@default-6: #6694CC;
@default-7: #3d74b8;
@default-8: #2e578a;
@default-9: #0F1D2E;

@color-square-default: #F8F9FA;
@color-square-blue: @default-1;
@color-square-orange: #FEF3EE;

@bg-gradient: linear-gradient(#fff 55%, @default-2 50%);

@white: #fff;
@lavender: #D6E2FF;
@success: #0085DA;
@secondary: #00c795;
@secondary-2: #3165d9;
@secondary-3: #ba3d9d;

@color-make: #6d00cc;
@color-dark: #000C29;
@color-dark-lighter: lighten(#000c29, 6%);

@tbd-success-5: #4197BE;
@tbd-success-6: #367C9C;
@tbd-primary-5: #62a8ea;
@tbd-primary-6: #518AC0;
@tbd-danger-5: #d9534f;
@tbd-danger-6: #B24441;
@tbd-warning-5: #F0AD4E;
@tbd-warning-6: #C58E40;

@btn-success-bg: @success;
@btn-success-bg-hover: darken(@success, 7%);
@btn-success-fg: @white;

@btn-secondary-bg: @secondary;
@btn-secondary-bg-hover: darken(@secondary, 7%);
@btn-secondary-fg: @white;

@btn-default-bg: transparent;
@btn-default-bg-hover: #dae5f1;
@btn-default-border: #aec6e2;

@br-sm: 0.375rem;
@br-md: 1rem;
@br-lg: 1.25rem;
@br-xlg: 1.8rem;

@br-btn: 0.5rem;
@br-btn-sm: 1.5rem;

@font-xxs: .75rem;
@font-xs: .875rem;
@font-sm: .9375rem;
@font-md: 1rem;
@font-lg: 1.125rem;
@font-xlg: 1.25rem;
@font-xxlg: 1.75rem;

@font-h1: 3.125rem;
@font-h1-xs: 1.875rem;
@font-h2: 2.5rem;
@font-h2-xs: 1.5rem;
@font-h3: 1.875rem;
@font-h4: 1.5625rem;
@font-h5: 1.25rem;
@font-h6: 1.25rem;

@line-height-sm: 1.125;
@line-height: 1.25;
@line-height-lg: 1.6;
@line-height-xlg: 1.75;

@fw-bolder: 500;
@fw-bold: 700;

@featureColors: #f16b26, @success, #08b4dd, #c4b000, #5400c7, #104fdd;

@screen-lg-min: 72rem;
@screen-md-max: 71.99rem;
@screen-md-min: 52rem;
@screen-sm-max: 51.99rem;
@screen-sm-min: 40rem;
@screen-xs-max: 39.99rem;

@color-success-alt-1: #DAFBEF;
@color-success-alt-5: #16DB93;
@color-success-alt-7: #0F9563;

@color-crm: #FF5A5F;
@color-crm-alt: #FFF5F6;
@color-personalistika: #2766FF;
@color-personalistika-alt: #F1F4FF;
@color-rizeni-majetku: @color-success-alt-5;
@color-rizeni-majetku-alt: @color-success-alt-1;
@color-firemni-provoz: #23B5D3;
@color-firemni-provoz-alt: #e8f8fb;
@color-sprava-dokumentu: #FD6A40;
@color-sprava-dokumentu-alt: #fff7f5;