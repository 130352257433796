@import '../variables.less';

// -- new landing pages

@menu-item-padding: 1.5rem;
@menu-height: 72px;

.tabidoo-logo {

    width: 110px;

    @media screen and (min-width: @screen-lg-min) {
        width: 132px;
    }
}

.sticky-menu {
    background: #fff;
    // box-shadow: 0 4px 8px 0 var(--success-2);
    position: fixed;
    height: @menu-height;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.menu-placeholder,
.menu-wrapper {
    height: @menu-height;
}

.menu-wrapper {
    @media screen and (max-width: @screen-lg-max) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.menu {

    ul {
        display: flex;

        li {
            a {
                align-items: center;
                color: var(--success-9);
                font-size: 15px;
                display: flex;
                font-weight: 500;
                padding: 0rem @menu-item-padding;
                position: relative;
                height: @menu-height;

                &:hover {
                    background: @default-1;
                }

                &.active::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background-color: var(--success);
                    bottom: 0;
                    left: @menu-item-padding;
                    right: @menu-item-padding;
                }
            }
        }
    }
}

.sub-menu {
    background: var(--success-1);
    box-shadow: 0 4px 8px 0 var(--success-2);
    display: none;

    &.open {
        display: block;
    }
}

.sub-menu-pipe {
    background: var(--success-2);
    height: 36px;
    width: 1px;
}

.text-panel {

    @media screen and (min-width: @screen-sm-min) {
        width: 480px;
    }
}

.view-menu {
    background: rgba(255, 255, 255, 0.1);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    padding: 0 1.5rem;

    ul {
        display: flex;

        li {
            a {
                align-items: center;
                display: flex;
                font-size: 18px;
                justify-content: center;
                height: 80px;
                padding: 0rem @menu-item-padding;
                position: relative;

                &:hover {
                    background: rgba(255, 255, 255, 0.05);
                    color: #fff;
                }

                &.active {
                    color: #fff;

                    &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        background-color: var(--success);
                        bottom: 0;
                        left: @menu-item-padding;
                        right: @menu-item-padding;
                    }
                }
            }
        }
    }
}

.footer-list {
    li a {
        font-size: @font-sm;
    }
}


// -- end new landing pages


.menu-panel {
    display: none;
    position: fixed;
    background: #fff;
    left: 0;
    top: 0rem;
    right: 0;
    bottom: 0;

    &.open {
        display: block;
    }

    a.link {
        &:after {
            opacity: 1;
        }

        &:hover:after {
            opacity: 0;
        }
    }
}

.langs {
    a {
        font-size: @font-xs;
    }
}

.img-item {
    min-width: 4.3rem;
    margin-top: 0.3rem;
}

.img-main {
    width: 113%;
}

.img-functions {
    margin-left: -5rem;
    width: 89%;
    margin-top: 1.5rem;
    position: relative;
}

.img-functions-bg {
    &:before {
        content: '';
        position: absolute;
        width: 683px;
        height: 600px;
        background-image: url(../../img/bg/bg-2.png);
        background-repeat: no-repeat;
        background-position: center center;
        left: -73px;
        top: 53px;

        @media screen and (max-width: @screen-md-max) {
            display: none;
        }
    }
}

.dot {
    background-color: @success;
    height: 3px;
    width: 3px;
}

.benefit-list {
    background-color: @default-1;
    border-radius: @br-xlg;
    padding: 1.5rem 1rem;

    p {
        font-size: @font-sm;
    }

    .benefit-list-item {
        padding: 1.5rem 1.8rem;
        min-width: 12rem;
        max-width: 14rem;

        &.active {
            @media screen and (min-width: @screen-sm-min) {
                background: #fff;
                border-radius: @br-lg;
                z-index: 1;
                box-shadow: @box-shadow-lg;
                margin-top: -3rem;
                padding: 4.5rem 1.8rem 0 1.8rem;
            }
        }
    }
}

.benefit-list-vertical {
    background-color: @default-1;
    border-radius: @br-xlg;
    padding: 1.5rem 1.5rem;
    width: 300px;

    .benefit-list-item {
        font-weight: 600;
        padding: 2.5rem 1.8rem;
        width: 310px;
        align-content: center;
        transition: all 0.5s ease-in-out;

        &.active,
        &:hover {
            @media screen and (min-width: @screen-sm-min) {
                margin-right: -2.5rem;
                background: #fff;
                border-radius: @br-lg;
                z-index: 1;
                box-shadow: @box-shadow-lg;
            }
        }
    }
}

.card-item {
    background-color: @white;
    border-radius: @br-xlg;
    box-shadow: @box-shadow-lg;
    padding: 3.5rem 2.5rem;
    margin-right: 2.6rem;
    text-align: left;

    &:last-child {
        @media screen and (min-width: @screen-md-min) {
            margin-right: 0;
        }
    }
}

.solution-item {

    h3 {
        font-weight: @fw-bolder;
    }

    p {
        font-size: @font-sm;
        line-height: 1.5;
    }

    .btn {
        padding: 1rem 3rem;
    }

    ul li {
        font-size: @font-xs;
    }
}

.testimonial-item {
    color: @base-color-second;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &:hover {
        background: rgba(0, 0, 0, 0);
    }

    p {
        font-size: @font-md;
        font-style: italic;
    }
}

.testimonial-logo {
    img {
        max-width: 260px;
        max-height: 41px;
    }
}

.testimonial-name {
    font-size: @font-md;
    font-weight: 700;
}

.features {
    @media screen and (min-width: @screen-md-min) {
        margin-top: 20rem;
    }
}

.features-panel {
    margin-top: 7rem;

    @media screen and (max-width: @screen-xs-max) {
        margin-top: 1rem;
    }
}

.features-panel-left {
    @media screen and (min-width: @screen-md-min) {
        max-width: 20rem;
        padding-right: 3rem;
        flex: 1;
    }
}

.features-panel-right {
    flex: 2;
    display: flex;
    justify-content: center;
}

.feature-list {
    display: flex;
}

.feature-item {
    .number {
        font-size: @font-lg;

        each(@featureColors, {
                &.color-@{index} {
                    color: @value;
                }
            }

        );
    }

    p {
        font-size: @font-md;
    }
}

.how-to-item {
    max-width: 19.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
    padding-left: 0.5rem;

    &>div {
        height: 15.5rem;

        &.check {
            height: 2.5rem;
        }
    }
}


.inspiration-item-wrapper {
    width: 18.375rem;
    min-width: 16rem;

    // &:before {
    //     background: url(../../img/bg/grid-dots.png);
    //     content: '';
    //     height: 161px;
    //     left: -44px;
    //     position: absolute;
    //     top: -26px;
    //     width: 161px;
    // }
}


.inspiration-item {
    background: @white;
    border-radius: @br-lg;
    box-shadow: @box-shadow-lg;
    display: block;
    padding: 4.5rem 2rem 2rem 2rem;
    position: relative;
    height: 18.5rem;
    width: 18.375rem;
    min-width: 16rem;

    &:hover {
        background: #fafcff;
        box-shadow: @box-shadow-xlg;
    }

    &:active {
        background: @white;
        box-shadow: @box-shadow-sm;
    }

    h4 {
        font-weight: normal;

        &+p {
            font-size: @font-sm;
        }
    }
}

.integrations {

    background-image: url(../../img/icons/integrations/zapier-logos.png);
    background-position: center center;

    p {
        max-width: 38rem;
    }

    .integrators {
        max-height: 4rem;
    }
}

.case-studies-panel {

    margin-top: 4rem;

    @media screen and (min-width: @screen-lg-min) {
        margin-top: -15rem;
    }
}

.check-item {
    max-width: 14rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

    &:last-child {
        margin-right: 0;
    }
}

.feature-img {
    top: -21rem;
}

.s2 {
    height: 0.66rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 2rem;
    }
}

.s5 {
    height: 1.6rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 5rem;
    }
}

.s10 {
    height: 3.3rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 10rem;
    }
}

.s15 {
    height: 5rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 15rem;
    }
}

.s20 {
    height: 6.66rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 20rem;
    }
}

.s30 {
    height: 10rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 30rem;
    }
}

.s35 {
    height: 11.6rem;

    @media screen and (min-width: @screen-sm-min) {
        height: 35rem;
    }
}

.spacer {
    height: 28rem;
}

.splide__arrow--prev {
    left: -1em !important;
}

.splide__arrow--next {
    right: 0em !important;
}

.splide__arrow svg {
    fill: @success  !important;
}

.splide__pagination {
    bottom: -5.5em !important;
}

.splide__pagination__page.is-active {
    background: @success  !important;
}

.right-arrow {
    height: 1.6rem;
    margin-left: 20px;
}

.bg-window {
    align-items: baseline;
    border-radius: 12px;
    display: flex;
    height: 403px;
    justify-content: center;
    // padding-top: 1rem;
    width: 658px;

    @media screen and (max-width: @screen-xs-max) {
        height: 275px;
        width: 100%;
    }
}

.bg-window-1 {
    background: @default-3;
    position: relative;
}

.bg-window-2 {
    margin-left: 8rem;
    background: @color-square-orange;
}

.form-pict {
    position: absolute;
    opacity: 0;
    margin-left: 1.25rem;
    margin-top: -3px;
    transition: ease-in-out .5s all;

    @media screen and (max-width: @screen-xs-max) {
        max-width: 500px;
    }

    &.form-pict-canban {
        margin-top: -26px;
    }

    &.show {
        opacity: 1;
        transition: ease-in-out .5s all;
    }
}

.intro-image {
    margin-top: -4rem;
    margin-left: 4rem;
}

.video-wrapper {
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 20px 25px 0 rgba(0, 36, 60, .15);
    display: flex;
    height: 315px;
    justify-content: center;
    margin-top: 12px;
    margin-right: -3rem;
    overflow: hidden;
    position: relative;
    width: 560px;

    &.video-wrapper-center {
        margin: -14rem auto 0;
    }
}


.intro-bg-cube {
    left: -5rem;
    position: absolute;
    top: 8rem;
    z-index: -1;
}

.features-bg {
    background: @default-1;
}

.features-arrow {
    position: absolute;
    top: 2.5rem;
    left: -11rem;
}

.references-list {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 65rem;
    min-width: 45rem;
}

.reference-item {
    align-items: center;
    display: flex;
    filter: grayscale(1);
    opacity: 0.3;
}

.basics-panel {
    width: 100%;
    flex-direction: column-reverse;

    @media screen and (min-width: @screen-xlg-min) {
        flex-direction: row;
    }
}

.basics-panel-carousel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: @screen-xlg-min) {
        align-items: flex-start;
        margin-left: -11rem;
    }
}

.basics-panel-carousel {
    width: 100%;

    @media screen and (min-width: @screen-xlg-min) {
        width: 50%;
    }
}

.basics-panel-text {
    margin: 0 auto;
    padding: 0 0 5rem 0;
    text-align: center;
    width: 80%;

    @media screen and (min-width: @screen-xlg-min) {
        padding: 3rem 0;
        text-align: left;
        width: 50%;
    }

}

.basics-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 658px;
    margin-top: 4rem;
}

.basics-button {
    cursor: pointer;
    border-radius: 0.75rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 115px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    justify-content: space-between;
    color: @default-8;

    svg {
        margin-bottom: 0.75rem;
        fill: #BDD0E7;
    }

    &.active,
    &:hover {
        color: @default-9;
        box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.05), 0px 9px 20px rgba(0, 0, 0, 0.06);

        svg {
            fill: #94B4DB;
        }
    }
}

.column-panel {
    box-shadow: 0 6px 40px #d8e3f0;
    border-radius: 1.25rem;
}

.price {
    font-size: 1.5rem;
    font-weight: 500;

    @media screen and (min-width: 1100px) {
        font-size: 1.8rem;
    }

    @media screen and (min-width: 1300px) {
        font-size: 2.1rem;
    }
}

.fd-panel {
    padding: 3.5rem;
    width: 548px;
}

.grid-column-2 {
    margin-bottom: 3rem;
    width: 100%;

    @media screen and (min-width: @screen-md-min) {
        width: 46%;
    }
}

.grid-column-3 {
    width: 100%;

    @media screen and (min-width: @screen-md-min) {
        width: 46%;
    }

    @media screen and (min-width: @screen-lg-min) {
        width: 32%;
    }
}

.square-md {
    height: 64px;
    width: 64px;
}

.square-sm {
    height: 36px;
    width: 36px;
}

.square-xs {
    height: 24px;
    width: 24px;
}

.gallery {
    background: #fff;
}

.gallery-cell {
    width: 66%;
    max-width: 800px;
    height: 451px;
    // background: #8C8;
    // counter-increment: gallery-cell;

    img {
        max-width: 800px;
    }
}

.gallery-cell img {
    border-radius: 16px;
}

.gallery-cell:not(.is-selected) {
    opacity: 0.3;
    transform: scale(0.8);
}

.p-line {
    padding-left: 2rem;
    position: relative;

    &:before {
        background: @success;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        width: 3px;
    }

    &.p-line-crm {
        &:before {
            background: @color-crm;
        }
    }

    &.p-line-personalistika {
        &:before {
            background: @color-personalistika;
        }
    }

    &.p-line-rizeni-majetku {
        &:before {
            background: @color-rizeni-majetku;
        }
    }

    &.p-line-firemni-provoz {
        &:before {
            background: @color-firemni-provoz;
        }
    }

    &.p-line-sprava-dokumentu {
        &:before {
            background: @color-sprava-dokumentu;
        }
    }
}

.carier-card {
    min-width: 320px;

    @media screen and (min-width: @screen-sm-min) {
        min-width: 400px;
    }

    @media screen and (min-width: @screen-lg-min) {
        min-width: 500px;
    }

    &:hover {
        opacity: 0.7;
    }
}

.reference-grid {}