.animate {
  transition: all 85ms ease-in;
}

.br-sm {
  border-radius: .375rem;
}

.br-md {
  border-radius: 1rem;
}

.br-lg {
  border-radius: 1.25rem;
}

.br-50 {
  border-radius: 50%;
}

.border-none {
  border: none;
}

.color-white {
  color: #fff;
}

.white {
  color: #fff !important;
}

.success-2 {
  color: var(--success-2);
}

.success-4 {
  color: var(--success-4);
}

.success-9 {
  color: var(--success-9);
}

.color-lavender {
  color: #d6e2ff;
}

.bg-white {
  background-color: #fff;
}

.bg-dark {
  background-color: #000c29;
}

.color-dark {
  color: #000c29;
}

.bg-dark-lighter {
  background-color: #001548;
}

.bg-dark-lighter-white {
  background: linear-gradient(#001548 50%, #fff 50%);
}

.color-success {
  color: var(--success);
}

.primary {
  color: var(--primary);
}

.bg-success-alt {
  background: var(--success-alt);
}

.tbd-success {
  color: #4197be;
}

.tbd-primary {
  color: #62a8ea !important;
}

.tbd-danger {
  color: #d9534f !important;
}

.tbd-warning {
  color: #f0ad4e;
}

.tbd-green {
  color: #00ca9a !important;
}

.color-make {
  color: #6d00cc;
}

.bg-success {
  background: var(--success);
}

.color-crm {
  color: #ff5a5f;
}

.color-personalistika {
  color: #2766ff;
}

.color-rizeni-majetku {
  color: #16db93;
}

.color-firemni-provoz {
  color: #23b5d3;
}

.color-sprava-dokumentu {
  color: #fd6a40;
}

.bg-crm {
  background: #ff5a5f;
}

.bg-crm-alt {
  background: #fff5f6;
}

.bg-personalistika {
  background: #2766ff;
}

.bg-personalistika-alt {
  background: #f1f4ff;
}

.bg-rizeni-majetku {
  background: #16db93;
}

.bg-rizeni-majetku-alt {
  background: #dafbef;
}

.bg-firemni-provoz {
  background: #23b5d3;
}

.bg-firemni-provoz-alt {
  background: #e8f8fb;
}

.bg-sprava-dokumentu {
  background: #fd6a40;
}

.bg-sprava-dokumentu-alt {
  background: #fff7f5;
}

@media screen and (width <= 39.99rem) {
  .hide-xs {
    display: none !important;
  }
}

@media screen and (width <= 51.99rem) {
  .hide-sm {
    display: none !important;
  }
}

@media screen and (width <= 71.99rem) {
  .hide-md {
    display: none !important;
  }
}

@media screen and (width <= 39.99rem) {
  .hide.visible-xs {
    display: block !important;
  }
}

@media screen and (width >= 40rem) and (width <= 51.99rem) {
  .hide.visible-sm {
    display: block !important;
  }
}

.d-block {
  display: block;
}

.d-block-lg {
  display: none;
}

@media screen and (width >= 72rem) {
  .d-block-lg {
    display: block;
  }
}

.hide {
  display: none !important;
}

.flex-auto {
  flex: auto;
}

.flex {
  display: flex;
}

@media screen and (width >= 72rem) {
  .flex-md {
    display: flex;
  }
}

@media screen and (width <= 39.99rem) {
  .flex-column-reverse-xs {
    flex-direction: column-reverse;
  }

  .flex-column-xs {
    flex-direction: column;
  }
}

.flex-column {
  flex-direction: column;
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-vertical-center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evently {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

@media screen and (width >= 72rem) {
  .justify-end-lg {
    justify-content: flex-end;
  }
}

@media screen and (width <= 39.99rem) {
  .justify-center-xs {
    justify-content: center !important;
  }
}

@media screen and (width <= 51.99rem) {
  .justify-center-sm {
    justify-content: center !important;
  }
}

.font-xs {
  font-size: .875rem;
}

.font-sm {
  line-height: 1.75;
  font-size: .9375rem !important;
}

.font-md {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.125rem;
}

.font-xlg {
  font-size: 1.25rem;
}

.font-xxlg {
  font-size: 1.75rem;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.6667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333%;
}

.col-5 {
  width: 41.6667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333%;
}

.col-8 {
  width: 66.6667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333%;
}

.col-11 {
  width: 91.6667%;
}

.col-12 {
  width: 100%;
}

@media screen and (width >= 40rem) {
  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-12 {
    width: 100%;
  }
}

@media screen and (width >= 52rem) {
  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-12 {
    width: 100%;
  }
}

@media screen and (width >= 72rem) {
  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-12 {
    width: 100%;
  }
}

@media screen and (width >= 1440px) {
  .col-xlg-1 {
    width: 8.33333%;
  }

  .col-xlg-2 {
    width: 16.6667%;
  }

  .col-xlg-3 {
    width: 25%;
  }

  .col-xlg-4 {
    width: 33.3333%;
  }

  .col-xlg-5 {
    width: 41.6667%;
  }

  .col-xlg-6 {
    width: 50%;
  }

  .col-xlg-7 {
    width: 58.3333%;
  }

  .col-xlg-8 {
    width: 66.6667%;
  }

  .col-xlg-9 {
    width: 75%;
  }

  .col-xlg-10 {
    width: 83.3333%;
  }

  .col-xlg-11 {
    width: 91.6667%;
  }

  .col-xlg-12 {
    width: 100%;
  }
}

.text-highlight {
  background-image: linear-gradient(-100deg, #ffe0004d, #ffe000b3 95%, #ffe0001a);
  border-radius: 14% 0;
}

.object-cover {
  object-fit: cover;
}

.o-hover:hover {
  opacity: .7;
}

.o1 {
  opacity: 1 !important;
}

.o0 {
  opacity: 0;
}

.o50 {
  opacity: .5 !important;
}

.o75 {
  opacity: .75 !important;
}

.o85 {
  opacity: .85 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fading {
  animation: 2.5s infinite fading;
}

.p-box {
  background: #e7f1fd;
  border-radius: 8px;
  width: 100%;
  height: 12px;
  font-size: 0;
  line-height: 0;
  animation: 2.5s infinite fading;
  display: inline-block;
}

.p-box-lg {
  height: 80px;
}

@keyframes fading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.t0 {
  top: 0;
}

.b0 {
  bottom: 0;
}

.l0 {
  left: 0;
}

.l5 {
  left: 5rem;
}

.l10 {
  left: 10rem;
}

.r0 {
  right: 0;
}

.r5 {
  right: 5rem;
}

.t15m {
  top: -15%;
}

.t20m {
  top: -20%;
}

.t30m {
  top: -30%;
}

.t35m {
  top: -35%;
}

.t40m {
  top: -40%;
}

.t50m {
  top: -50%;
}

.t70m {
  top: -70%;
}

.l50 {
  left: 50%;
}

.shadow-sm {
  box-shadow: 0 6px 16px #d8e3f0;
}

.shadow-md {
  box-shadow: 0 6px 25px #d8e3f0;
}

.h-1 {
  height: 1rem;
}

.h-2 {
  height: 2rem;
}

.h-3 {
  height: 3rem;
}

.h-4 {
  height: 4rem;
}

.h-5 {
  height: 5rem;
}

.min-h-100-vh {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.max-w-300 {
  max-width: 300px;
}

.min-w-320 {
  min-width: 320px;
}

.max-w-full {
  max-width: 100%;
}

.m0 {
  margin: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.m1 {
  margin: .25rem !important;
}

.p1 {
  padding: .25rem !important;
}

.m2 {
  margin: .5rem !important;
}

.p2 {
  padding: .5rem !important;
}

.m3 {
  margin: 1rem !important;
}

.p3 {
  padding: 1rem !important;
}

.m4 {
  margin: 2rem !important;
}

.p4 {
  padding: 2rem !important;
}

.m5 {
  margin: 4rem !important;
}

.p5 {
  padding: 4rem !important;
}

.m6 {
  margin: 6rem !important;
}

.p6 {
  padding: 6rem !important;
}

.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.my1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.mx1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.py1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.px1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.my2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.mx2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.py2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.px2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.my3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.py3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.my4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.py4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.my5 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx5 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.py5 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px5 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.my6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mx6 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.py6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.px6 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.ml0 {
  margin-left: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.ml1 {
  margin-left: .25rem !important;
}

.pl1 {
  padding-left: .25rem !important;
}

.mt1 {
  margin-top: .25rem !important;
}

.pt1 {
  padding-top: .25rem !important;
}

.mb1 {
  margin-bottom: .25rem !important;
}

.pb1 {
  padding-bottom: .25rem !important;
}

.mr1 {
  margin-right: .25rem !important;
}

.pr1 {
  padding-right: .25rem !important;
}

.ml2 {
  margin-left: .5rem !important;
}

.pl2 {
  padding-left: .5rem !important;
}

.mt2 {
  margin-top: .5rem !important;
}

.pt2 {
  padding-top: .5rem !important;
}

.mb2 {
  margin-bottom: .5rem !important;
}

.pb2 {
  padding-bottom: .5rem !important;
}

.mr2 {
  margin-right: .5rem !important;
}

.pr2 {
  padding-right: .5rem !important;
}

.ml3 {
  margin-left: 1rem !important;
}

.pl3 {
  padding-left: 1rem !important;
}

.mt3 {
  margin-top: 1rem !important;
}

.pt3 {
  padding-top: 1rem !important;
}

.mb3 {
  margin-bottom: 1rem !important;
}

.pb3 {
  padding-bottom: 1rem !important;
}

.mr3 {
  margin-right: 1rem !important;
}

.pr3 {
  padding-right: 1rem !important;
}

.ml4 {
  margin-left: 2rem !important;
}

.pl4 {
  padding-left: 2rem !important;
}

.mt4 {
  margin-top: 2rem !important;
}

.pt4 {
  padding-top: 2rem !important;
}

.mb4 {
  margin-bottom: 2rem !important;
}

.pb4 {
  padding-bottom: 2rem !important;
}

.mr4 {
  margin-right: 2rem !important;
}

.pr4 {
  padding-right: 2rem !important;
}

.ml5 {
  margin-left: 4rem !important;
}

.pl5 {
  padding-left: 4rem !important;
}

.mt5 {
  margin-top: 4rem !important;
}

.pt5 {
  padding-top: 4rem !important;
}

.mb5 {
  margin-bottom: 4rem !important;
}

.pb5 {
  padding-bottom: 4rem !important;
}

.mr5 {
  margin-right: 4rem !important;
}

.pr5 {
  padding-right: 4rem !important;
}

.ml6 {
  margin-left: 6rem !important;
}

.pl6 {
  padding-left: 6rem !important;
}

.mt6 {
  margin-top: 6rem !important;
}

.pt6 {
  padding-top: 6rem !important;
}

.pb6 {
  padding-bottom: 6rem !important;
}

.mr6 {
  margin-right: 6rem !important;
}

.pr6 {
  padding-right: 6rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (width >= 72rem) {
  .mt5-lg {
    margin-top: 4rem !important;
  }

  .ml5-lg {
    margin-left: 4rem !important;
  }
}

@media screen and (width >= 40rem) {
  .mt5-sm {
    margin-top: 4rem !important;
  }

  .px5-sm {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

@media screen and (width <= 39.99rem) {
  .pb5-xs {
    padding-bottom: 4rem !important;
  }

  .mr3-xs {
    margin-right: 1rem !important;
  }

  .py5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .my5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt5 {
    margin-top: 2rem !important;
  }

  .mb5 {
    margin-bottom: 2rem !important;
  }

  .pt5 {
    padding-top: 2rem !important;
  }

  .pb5 {
    padding-bottom: 2rem !important;
  }
}

.ml-3 {
  margin-left: -1rem;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z100 {
  z-index: 100;
}

.z101 {
  z-index: 101;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

:root {
  --success-0: #f1f4ff;
  --success-1: #ebf0ff;
  --success-2: #d6e2ff;
  --success-4: #5c8aff;
  --success: #2766ff;
  --success-6: #0045f5;
  --success-9: #000c29;
  --success-alt: #b6d3e6;
  --primary-4: #16db93;
  --primary: #13c282;
  --primary-6: #0f9563;
  --green: #08ce35;
  --warning: #f0ad4e;
  --color-btn-success: var(--success);
  --color-btn-success-hover: var(--success-4);
  --color-btn-success-on: #fff;
  --color-btn-success-alt: var(--success);
  --color-btn-success-alt-hover: var(--success-4);
  --color-btn-success-alt-on: #fff;
}

.btn {
  letter-spacing: .01rem;
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .25rem 1rem;
  font-size: 14.5px;
  font-weight: 700;
  display: inline-flex;
  position: relative;
}

@media screen and (width >= 40rem) {
  .btn {
    padding: .25rem 1.5rem;
  }
}

@media screen and (width >= 52rem) {
  .btn {
    font-size: 15px;
  }
}

@media screen and (width >= 72rem) {
  .btn {
    font-size: 16px;
  }
}

@media screen and (width >= 1440px) {
  .btn {
    font-size: 18px;
  }
}

.btn.btn-sm {
  padding: 0 1.5rem;
  font-size: .9375rem;
}

.btn.btn-sm.btn-hamburger {
  padding: .5rem;
}

.btn.btn-lg {
  border-radius: .75rem;
  padding: .5rem 1.75rem;
}

.btn.btn-arrow {
  padding-right: 2.5rem;
}

@media screen and (width >= 40rem) {
  .btn.btn-arrow {
    padding-right: 3.2rem;
  }
}

.btn.btn-arrow:after {
  content: "";
  background-image: url("angle-right.d2175fcf.svg");
  background-repeat: no-repeat;
  width: 1rem;
  height: 1.7rem;
  position: absolute;
  top: 52%;
  right: .5rem;
  transform: translateY(-50%);
}

@media screen and (width >= 40rem) {
  .btn.btn-arrow:after {
    right: 1rem;
  }
}

.btn.btn-link {
  color: #0085da;
}

.btn.btn-link:hover {
  opacity: .9;
}

.btn.btn-link.btn-arrow:after {
  background-image: url("angle-right-blue.c2959b41.svg");
}

.btn-default, .btn-default-alt {
  border: 1px solid var(--success);
  color: var(--success);
}

.btn-default:hover, .btn-default-alt:hover {
  background: #dae5f1;
  border-color: #0000;
}

.btn-default-alt {
  background: #fff;
  border-color: #0000;
}

.btn-default-alt:hover {
  opacity: .7;
  background: #fff;
}

.btn-tbd-success {
  color: #fff;
  background: #4197be;
}

.btn-tbd-success:hover {
  background: #367c9c;
}

.btn-tbd-danger {
  color: #fff;
  background: #d9534f;
}

.btn-tbd-danger:hover {
  background: #b24441;
}

.btn-tbd-primary {
  color: #fff;
  background: #62a8ea;
}

.btn-tbd-primary:hover {
  background: #518ac0;
}

.btn-tbd-warning {
  color: #fff;
  background: #f0ad4e;
}

.btn-tbd-warning:hover {
  background: #c58e40;
}

.btn-success {
  color: var(--color-btn-success-on);
  background: var(--color-btn-success);
}

.btn-success:hover {
  background: var(--color-btn-success-hover);
}

.btn-success:active, .btn-success:focus {
  background: var(--success-6);
}

.btn-success-alt {
  color: var(--success);
  background: var(--success-0);
}

.btn-success-alt:hover {
  background: var(--success-1);
}

.btn-success-alt:active, .btn-success-alt:focus {
  background: var(--success-2);
}

.btn-primary {
  color: #fff;
  background: var(--primary);
}

.btn-primary:hover {
  background: var(--primary-4);
}

.btn-primary:active, .btn-primary:focus {
  background: var(--primary-6);
}

.btn-crm, .btn-personalistika, .btn-rizeni-majetku, .btn-firemni-provoz, .btn-sprava-dokumentu {
  color: #fff;
}

.btn-crm:hover, .btn-personalistika:hover, .btn-rizeni-majetku:hover, .btn-firemni-provoz:hover, .btn-sprava-dokumentu:hover {
  opacity: .8;
}

.btn-crm {
  background: #ff5a5f;
}

.btn-personalistika {
  background: #2766ff;
}

.btn-rizeni-majetku {
  background: #16db93;
}

.btn-firemni-provoz {
  background: #23b5d3;
}

.btn-sprava-dokumentu {
  background: #fd6a40;
}

.btn-secondary {
  color: #fff;
  background: #00c795;
}

.btn-secondary:hover {
  background: #00a37a;
}

@media screen and (width >= 72rem) {
  .btn-hamburger {
    display: none;
  }
}

.hamburger {
  background-color: var(--success);
  width: 20px;
  height: 2px;
  transition: all 85ms ease-in;
  position: relative;
}

.hamburger:before {
  content: "";
  background-color: var(--success);
  width: 20px;
  height: 2px;
  transition: all 85ms ease-in;
  position: absolute;
  top: -6px;
}

.hamburger:after {
  content: "";
  background-color: var(--success);
  width: 20px;
  height: 2px;
  transition: all 85ms ease-in;
  position: absolute;
  top: 6px;
}

.hamburger.open {
  background-color: #0000;
}

.hamburger.open:after, .hamburger.open:before {
  top: 0;
}

.hamburger.open:before {
  transform: rotate(45deg);
}

.hamburger.open:after {
  transform: rotate(-45deg);
}

body.tbd {
  color: #707a8c;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 2.25rem;
  text-decoration: none;
  position: relative;
  overflow: hidden auto;
}

@media screen and (width <= 39.99rem) {
  body.tbd {
    font-size: 1.125rem;
  }
}

* {
  box-sizing: border-box;
}

picture {
  display: flex;
}

picture img {
  height: auto;
}

a {
  color: var(--success-9);
  cursor: pointer;
}

input[type="button"] {
  cursor: pointer;
}

a:not(.underline) {
  text-decoration: none;
}

a:not(.btn) {
  position: relative;
}

a:not(.btn):after {
  content: "";
  position: absolute;
}

a.link:after, a.link-2:after {
  opacity: 0;
  content: "";
  background: #aec6e2;
  width: 100%;
  height: 2px;
  transition: all 85ms ease-in;
  position: absolute;
  bottom: -6px;
  left: 0;
}

a.link:hover:after, a.link-2:hover:after, a.link.active:after, a.link-2.active:after {
  opacity: 1;
}

a.link-2:after {
  opacity: 1;
  bottom: -4px;
}

a.link-2:hover:after {
  opacity: 0;
}

h1, .h1, h2, .h2, h3, .h3 h4, .h4, h5, .h5, h6, .h6, .h7 {
  color: var(--success-9);
}

h1, .h1 {
  letter-spacing: -.075rem;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.25;
}

@media screen and (width >= 52rem) {
  h1, .h1 {
    font-size: 2.65rem;
  }
}

@media screen and (width >= 72rem) {
  h1, .h1 {
    font-size: 2.8rem;
  }
}

@media screen and (width >= 1440px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}

h2, .h2 {
  letter-spacing: -.05rem;
  font-size: 2rem;
  font-weight: 800;
  line-height: 2.8rem;
}

@media screen and (width >= 52rem) {
  h2, .h2 {
    font-size: 34px;
  }
}

@media screen and (width >= 72rem) {
  h2, .h2 {
    font-size: 36px;
    line-height: 3.25rem;
  }
}

@media screen and (width >= 1440px) {
  h2, .h2 {
    font-size: 40px;
  }
}

h3, .h3 {
  font-size: 24px;
  font-weight: 800;
}

h3 + p, .h3 + p {
  font-size: 1rem;
}

@media screen and (width >= 52rem) {
  h3, .h3 {
    font-size: 25.5px;
  }
}

@media screen and (width >= 72rem) {
  h3, .h3 {
    font-size: 27px;
  }
}

@media screen and (width >= 1440px) {
  h3, .h3 {
    font-size: 30px;
  }
}

h4, .h4 {
  font-size: 20px;
}

@media screen and (width >= 52rem) {
  h4, .h4 {
    font-size: 21px;
  }
}

@media screen and (width >= 72rem) {
  h4, .h4 {
    font-size: 22.5px;
  }
}

@media screen and (width >= 1440px) {
  h4, .h4 {
    font-size: 25px;
  }
}

h5, .h5 {
  font-size: 16px;
}

@media screen and (width >= 52rem) {
  h5, .h5 {
    font-size: 17px;
  }
}

@media screen and (width >= 72rem) {
  h5, .h5 {
    font-size: 18px;
  }
}

@media screen and (width >= 1440px) {
  h5, .h5 {
    font-size: 20px;
  }
}

h6, .h6 {
  font-size: 16px;
}

@media screen and (width >= 52rem) {
  h6, .h6 {
    font-size: 17px;
  }
}

@media screen and (width >= 72rem) {
  h6, .h6 {
    font-size: 18px;
  }
}

@media screen and (width >= 1440px) {
  h6, .h6 {
    font-size: 20px;
  }
}

.h7 {
  font-size: 14px;
}

@media screen and (width >= 52rem) {
  .h7 {
    font-size: 15px;
  }
}

@media screen and (width >= 72rem) {
  .h7 {
    font-size: 16px;
  }
}

@media screen and (width >= 1440px) {
  .h7 {
    font-size: 18px;
  }
}

.text-detail {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

p {
  color: #707a8c;
  font-size: 14.5px;
  line-height: 2rem;
}

@media screen and (width >= 52rem) {
  p {
    font-size: 15px;
  }
}

@media screen and (width >= 72rem) {
  p {
    font-size: 16px;
  }
}

@media screen and (width >= 1440px) {
  p {
    font-size: 18px;
  }
}

p.smaller {
  font-size: 13px;
}

@media screen and (width >= 52rem) {
  p.smaller {
    font-size: 14px;
  }
}

@media screen and (width >= 72rem) {
  p.smaller {
    font-size: 15px;
  }
}

@media screen and (width >= 1440px) {
  p.smaller {
    font-size: 16px;
  }
}

input, textarea {
  outline: none;
}

ul li {
  font-size: 1rem;
}

ul li a {
  color: #707a8c;
}

nav a {
  font-size: .9375rem;
}

.max-width-sm {
  max-width: 450px;
}

.max-width-md {
  max-width: 45rem;
}

.max-width-lg {
  max-width: 1200px;
}

.max-width-xlg {
  max-width: 1440px;
}

.subtitle {
  color: #0085da;
  letter-spacing: .15rem;
  text-transform: uppercase;
  font-size: .9375rem;
}

.dots {
  z-index: 1;
  position: relative;
}

.dots:before {
  content: "";
  z-index: -1;
  background: url("grid-dots.d8c16f54.png");
  width: 161px;
  height: 161px;
  position: absolute;
  top: 42px;
  left: -70px;
}

.mb6 {
  margin-bottom: 8rem;
}

.base-color {
  color: var(--success-9);
}

.success {
  color: var(--success);
}

.success-alt-1 {
  color: #dafbef;
}

.success-alt-5 {
  color: #16db93;
}

.success-alt-7 {
  color: #0f9563;
}

.secondary {
  color: #00c795;
}

.secondary-2 {
  color: #3165d9;
}

.secondary-3 {
  color: #ba3d9d;
}

.bg-secondary {
  background-color: #00c795;
}

.bg-secondary-2 {
  background-color: #3165d9;
}

.bg-secondary-3 {
  background-color: #ba3d9d;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 500;
}

.bg-svg {
  z-index: -1;
  position: absolute;
}

.caret {
  background: url("caret.92ea03e7.svg") 0 0 / 1rem 1rem;
  width: 1rem;
  height: 1rem;
  display: block;
}

.dropdown-menu {
  z-index: 1000;
  background: #fff;
  border-radius: .375rem;
  width: 373.333px;
  margin-left: 50%;
  padding: 1rem;
  display: none;
  position: absolute;
  top: 2rem;
  left: -186.667px;
  box-shadow: 0 6px 16px #d8e3f0;
}

.dropdown-menu.open {
  display: block;
}

.dropdown-menu:before {
  content: " ";
  background-position: 50% 0;
  border-bottom: 10px solid #fff;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  height: 0;
  margin-left: -10px;
  display: block;
  position: absolute;
  top: -9px;
  left: 50%;
}

.dropdown-menu li {
  border-radius: .375rem;
  margin: 0;
}

.dropdown-menu li:hover {
  background-color: #f2f8ff;
}

.dropdown-menu a {
  padding: .5rem 1rem;
  display: block;
  position: relative;
}

.text-content h2 {
  margin: 2rem 0 1.5rem;
}

.text-content h3 {
  margin: 1rem 0;
}

.text-content p {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.list {
  margin: 1rem 0;
}

.list > ul {
  margin: 1.5rem 0 1.5rem 1.5rem;
}

.list li {
  color: #707a8c;
  padding-left: 1.25rem;
  position: relative;
}

.list li:before {
  content: "";
  background-color: #0085da;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}

.list.list-secondary li:before {
  background-color: #00c795;
}

.list.list-warning li:before {
  background-color: var(--warning);
}

.list.list-secondary-2 li:before {
  background-color: #3165d9;
}

.badge-label {
  background: var(--success);
  color: #fff;
  text-transform: uppercase;
  border-radius: .5rem;
  padding: 0 1rem;
  font-size: .9375rem;
  font-weight: 500;
}

.badge-label-done {
  background: var(--green);
}

.badge-label-warning {
  background: var(--warning);
}

.badge {
  background: var(--success-1);
  color: var(--success);
  border-radius: 2rem;
  margin-bottom: .25rem;
  margin-right: .5rem;
  padding: .2rem 2rem;
  font-size: .9375rem;
}

.badge-warning {
  color: #996f32;
  background: #fae8ce;
  font-weight: 500;
}

.collapsable {
  opacity: 1;
  transition: all .5s ease-in;
  display: block;
}

.collapsable.collapse {
  opacity: 0;
  display: none;
}

.quotemark {
  opacity: .3;
  width: 24px;
}

span[class*="hint--"] {
  border-bottom: 2px dotted #b0b4b8;
  padding: 2px 0;
}

span[class*="hint--"]:hover {
  border-bottom-color: #0000;
}

span[class*="hint--"][aria-label]:after {
  z-index: 2;
  font-weight: 400;
  line-height: 1.05rem;
}

span.hint-multi[class*="hint--"][aria-label]:after {
  white-space: pre-wrap;
  width: 160px;
}

.blog h2 {
  margin-top: 4rem;
}

.blog p {
  color: #222;
  margin-top: 2rem;
}

.blog ul {
  margin-top: 2rem;
  margin-left: 2rem;
}

.blog ul li {
  color: #222;
  font-size: 1rem;
  line-height: 2;
  position: relative;
}

.blog ul li:before {
  content: "";
  background-color: #0085da;
  border-radius: 2px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: .9rem;
  transform: translateY(-50%);
}

.blog ul li ul li:before {
  width: 6px;
  height: 6px;
}

.blog li > ul {
  margin-top: 1rem;
}

.info {
  letter-spacing: .15rem;
  text-transform: uppercase;
  font-size: .9375rem;
  font-weight: 500;
}

.p-info {
  font-size: 15px;
  line-height: 26.3px;
}

.testi {
  display: flex;
}

.testi .quotemark {
  position: absolute;
}

.testi .quote-left {
  left: -32px;
}

.testi .quote-right {
  margin-left: .5rem;
  padding-top: .5rem;
}

.testi-left {
  margin-left: .75rem;
}

.testi-logo, .testi-face {
  display: block;
}

.testi-face {
  object-fit: cover;
  border-radius: 12px;
  width: 154px;
  height: 154px;
  box-shadow: 0 3px 6px #00000029;
}

.testi-logo {
  max-width: 154px;
  display: block;
}

.testi-panel {
  border-radius: 12px;
  flex-direction: column;
  max-width: 573px;
  margin-left: 12px;
  padding: 2rem 3.5rem;
  display: flex;
}

.testi-quote {
  margin-bottom: 2rem;
  font-size: 19px;
  position: relative;
}

.testi-footer {
  align-items: center;
  display: flex;
}

.testi-name {
  text-transform: uppercase;
  margin-right: 1.5rem;
  font-size: 14px;
  font-weight: 700;
}

.testi-position {
  opacity: .8;
  margin-right: 1.5rem;
}

.testi.testi-success .testi-panel {
  background: #0085da;
  box-shadow: 0 3px 6px #00000029;
}

.testi.testi-success .testi-quote, .testi.testi-success .testi-footer, .testi.testi-success .link {
  color: #fff;
}

.testi.testi-big .testi-face {
  width: 202px;
  height: 202px;
}

.testi.testi-big .testi-logo {
  max-width: 202px;
}

.testi.testi-big .testi-quote {
  font-size: 24px;
}

.testi.testi-big .testi-panel {
  max-width: 700px;
  margin-left: 2.5rem;
}

.tabidoo-logo {
  width: 110px;
}

@media screen and (width >= 72rem) {
  .tabidoo-logo {
    width: 132px;
  }
}

.sticky-menu {
  z-index: 1000;
  background: #fff;
  width: 100%;
  height: 72px;
  position: fixed;
  top: 0;
}

.menu-placeholder, .menu-wrapper {
  height: 72px;
}

@media screen and (width <= 1439px) {
  .menu-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.menu ul {
  display: flex;
}

.menu ul li a {
  color: var(--success-9);
  align-items: center;
  height: 72px;
  padding: 0 1.5rem;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.menu ul li a:hover {
  background: #f2f8ff;
}

.menu ul li a.active:after {
  content: "";
  background-color: var(--success);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  right: 1.5rem;
}

.sub-menu {
  background: var(--success-1);
  box-shadow: 0 4px 8px 0 var(--success-2);
  display: none;
}

.sub-menu.open {
  display: block;
}

.sub-menu-pipe {
  background: var(--success-2);
  width: 1px;
  height: 36px;
}

@media screen and (width >= 40rem) {
  .text-panel {
    width: 480px;
  }
}

.view-menu {
  background: #ffffff1a;
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
  padding: 0 1.5rem;
}

.view-menu ul {
  display: flex;
}

.view-menu ul li a {
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 1.5rem;
  font-size: 18px;
  display: flex;
  position: relative;
}

.view-menu ul li a:hover {
  color: #fff;
  background: #ffffff0d;
}

.view-menu ul li a.active {
  color: #fff;
}

.view-menu ul li a.active:after {
  content: "";
  background-color: var(--success);
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 1.5rem;
  right: 1.5rem;
}

.footer-list li a {
  font-size: .9375rem;
}

.menu-panel {
  background: #fff;
  display: none;
  position: fixed;
  inset: 0;
}

.menu-panel.open {
  display: block;
}

.menu-panel a.link:after {
  opacity: 1;
}

.menu-panel a.link:hover:after {
  opacity: 0;
}

.langs a {
  font-size: .875rem;
}

.img-item {
  min-width: 4.3rem;
  margin-top: .3rem;
}

.img-main {
  width: 113%;
}

.img-functions {
  width: 89%;
  margin-top: 1.5rem;
  margin-left: -5rem;
  position: relative;
}

.img-functions-bg:before {
  content: "";
  background-image: url("bg-2.0585136f.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 683px;
  height: 600px;
  position: absolute;
  top: 53px;
  left: -73px;
}

@media screen and (width <= 71.99rem) {
  .img-functions-bg:before {
    display: none;
  }
}

.dot {
  background-color: #0085da;
  width: 3px;
  height: 3px;
}

.benefit-list {
  background-color: #f2f8ff;
  border-radius: 1.8rem;
  padding: 1.5rem 1rem;
}

.benefit-list p {
  font-size: .9375rem;
}

.benefit-list .benefit-list-item {
  min-width: 12rem;
  max-width: 14rem;
  padding: 1.5rem 1.8rem;
}

@media screen and (width >= 40rem) {
  .benefit-list .benefit-list-item.active {
    z-index: 1;
    background: #fff;
    border-radius: 1.25rem;
    margin-top: -3rem;
    padding: 4.5rem 1.8rem 0;
    box-shadow: 0 6px 40px #d8e3f0;
  }
}

.benefit-list-vertical {
  background-color: #f2f8ff;
  border-radius: 1.8rem;
  width: 300px;
  padding: 1.5rem;
}

.benefit-list-vertical .benefit-list-item {
  align-content: center;
  width: 310px;
  padding: 2.5rem 1.8rem;
  font-weight: 600;
  transition: all .5s ease-in-out;
}

@media screen and (width >= 40rem) {
  .benefit-list-vertical .benefit-list-item.active, .benefit-list-vertical .benefit-list-item:hover {
    z-index: 1;
    background: #fff;
    border-radius: 1.25rem;
    margin-right: -2.5rem;
    box-shadow: 0 6px 40px #d8e3f0;
  }
}

.card-item {
  text-align: left;
  background-color: #fff;
  border-radius: 1.8rem;
  margin-right: 2.6rem;
  padding: 3.5rem 2.5rem;
  box-shadow: 0 6px 40px #d8e3f0;
}

@media screen and (width >= 52rem) {
  .card-item:last-child {
    margin-right: 0;
  }
}

.solution-item h3 {
  font-weight: 500;
}

.solution-item p {
  font-size: .9375rem;
  line-height: 1.5;
}

.solution-item .btn {
  padding: 1rem 3rem;
}

.solution-item ul li {
  font-size: .875rem;
}

.testimonial-item {
  color: #707a8c;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  display: flex;
}

.testimonial-item:hover {
  background: none;
}

.testimonial-item p {
  font-size: 1rem;
  font-style: italic;
}

.testimonial-logo img {
  max-width: 260px;
  max-height: 41px;
}

.testimonial-name {
  font-size: 1rem;
  font-weight: 700;
}

@media screen and (width >= 52rem) {
  .features {
    margin-top: 20rem;
  }
}

.features-panel {
  margin-top: 7rem;
}

@media screen and (width <= 39.99rem) {
  .features-panel {
    margin-top: 1rem;
  }
}

@media screen and (width >= 52rem) {
  .features-panel-left {
    flex: 1;
    max-width: 20rem;
    padding-right: 3rem;
  }
}

.features-panel-right {
  flex: 2;
  justify-content: center;
  display: flex;
}

.feature-list {
  display: flex;
}

.feature-item .number {
  font-size: 1.125rem;
}

.feature-item .number.color-1 {
  color: #f16b26;
}

.feature-item .number.color-2 {
  color: #0085da;
}

.feature-item .number.color-3 {
  color: #08b4dd;
}

.feature-item .number.color-4 {
  color: #c4b000;
}

.feature-item .number.color-5 {
  color: #5400c7;
}

.feature-item .number.color-6 {
  color: #104fdd;
}

.feature-item p {
  font-size: 1rem;
}

.how-to-item {
  max-width: 19.5rem;
  padding-top: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
}

.how-to-item > div {
  height: 15.5rem;
}

.how-to-item > div.check {
  height: 2.5rem;
}

.inspiration-item-wrapper {
  width: 18.375rem;
  min-width: 16rem;
}

.inspiration-item {
  background: #fff;
  border-radius: 1.25rem;
  width: 18.375rem;
  min-width: 16rem;
  height: 18.5rem;
  padding: 4.5rem 2rem 2rem;
  display: block;
  position: relative;
  box-shadow: 0 6px 40px #d8e3f0;
}

.inspiration-item:hover {
  background: #fafcff;
  box-shadow: 0 2px 5px #d8e3f0, 0 6px 40px #d8e3f0;
}

.inspiration-item:active {
  background: #fff;
  box-shadow: 0 6px 16px #d8e3f0;
}

.inspiration-item h4 {
  font-weight: normal;
}

.inspiration-item h4 + p {
  font-size: .9375rem;
}

.integrations {
  background-image: url("zapier-logos.9b5bd5b1.png");
  background-position: center;
}

.integrations p {
  max-width: 38rem;
}

.integrations .integrators {
  max-height: 4rem;
}

.case-studies-panel {
  margin-top: 4rem;
}

@media screen and (width >= 72rem) {
  .case-studies-panel {
    margin-top: -15rem;
  }
}

.check-item {
  max-width: 14rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.check-item:last-child {
  margin-right: 0;
}

.feature-img {
  top: -21rem;
}

.s2 {
  height: .66rem;
}

@media screen and (width >= 40rem) {
  .s2 {
    height: 2rem;
  }
}

.s5 {
  height: 1.6rem;
}

@media screen and (width >= 40rem) {
  .s5 {
    height: 5rem;
  }
}

.s10 {
  height: 3.3rem;
}

@media screen and (width >= 40rem) {
  .s10 {
    height: 10rem;
  }
}

.s15 {
  height: 5rem;
}

@media screen and (width >= 40rem) {
  .s15 {
    height: 15rem;
  }
}

.s20 {
  height: 6.66rem;
}

@media screen and (width >= 40rem) {
  .s20 {
    height: 20rem;
  }
}

.s30 {
  height: 10rem;
}

@media screen and (width >= 40rem) {
  .s30 {
    height: 30rem;
  }
}

.s35 {
  height: 11.6rem;
}

@media screen and (width >= 40rem) {
  .s35 {
    height: 35rem;
  }
}

.spacer {
  height: 28rem;
}

.splide__arrow--prev {
  left: -1em !important;
}

.splide__arrow--next {
  right: 0 !important;
}

.splide__arrow svg {
  fill: #0085da !important;
}

.splide__pagination {
  bottom: -5.5em !important;
}

.splide__pagination__page.is-active {
  background: #0085da !important;
}

.right-arrow {
  height: 1.6rem;
  margin-left: 20px;
}

.bg-window {
  border-radius: 12px;
  justify-content: center;
  align-items: baseline;
  width: 658px;
  height: 403px;
  display: flex;
}

@media screen and (width <= 39.99rem) {
  .bg-window {
    width: 100%;
    height: 275px;
  }
}

.bg-window-1 {
  background: #e7f1fd;
  position: relative;
}

.bg-window-2 {
  background: #fef3ee;
  margin-left: 8rem;
}

.form-pict {
  opacity: 0;
  margin-top: -3px;
  margin-left: 1.25rem;
  transition: all .5s ease-in-out;
  position: absolute;
}

@media screen and (width <= 39.99rem) {
  .form-pict {
    max-width: 500px;
  }
}

.form-pict.form-pict-canban {
  margin-top: -26px;
}

.form-pict.show {
  opacity: 1;
  transition: all .5s ease-in-out;
}

.intro-image {
  margin-top: -4rem;
  margin-left: 4rem;
}

.video-wrapper {
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 560px;
  height: 315px;
  margin-top: 12px;
  margin-right: -3rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 25px #00243c26;
}

.video-wrapper.video-wrapper-center {
  margin: -14rem auto 0;
}

.intro-bg-cube {
  z-index: -1;
  position: absolute;
  top: 8rem;
  left: -5rem;
}

.features-bg {
  background: #f2f8ff;
}

.features-arrow {
  position: absolute;
  top: 2.5rem;
  left: -11rem;
}

.references-list {
  justify-content: space-between;
  align-items: center;
  min-width: 45rem;
  max-width: 65rem;
  margin: 0 auto;
  display: flex;
}

.reference-item {
  filter: grayscale();
  opacity: .3;
  align-items: center;
  display: flex;
}

.basics-panel {
  flex-direction: column-reverse;
  width: 100%;
}

@media screen and (width >= 1440px) {
  .basics-panel {
    flex-direction: row;
  }
}

.basics-panel-carousel-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (width >= 1440px) {
  .basics-panel-carousel-wrapper {
    align-items: flex-start;
    margin-left: -11rem;
  }
}

.basics-panel-carousel {
  width: 100%;
}

@media screen and (width >= 1440px) {
  .basics-panel-carousel {
    width: 50%;
  }
}

.basics-panel-text {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  padding: 0 0 5rem;
}

@media screen and (width >= 1440px) {
  .basics-panel-text {
    text-align: left;
    width: 50%;
    padding: 3rem 0;
  }
}

.basics-buttons {
  justify-content: space-evenly;
  width: 658px;
  margin-top: 4rem;
  display: flex;
}

.basics-button {
  cursor: pointer;
  color: #2e578a;
  border-radius: .75rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 115px;
  padding: 1rem;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  position: relative;
}

.basics-button svg {
  fill: #bdd0e7;
  margin-bottom: .75rem;
}

.basics-button.active, .basics-button:hover {
  color: #0f1d2e;
  box-shadow: 0 3px 11px #0000000d, 0 9px 20px #0000000f;
}

.basics-button.active svg, .basics-button:hover svg {
  fill: #94b4db;
}

.column-panel {
  border-radius: 1.25rem;
  box-shadow: 0 6px 40px #d8e3f0;
}

.price {
  font-size: 1.5rem;
  font-weight: 500;
}

@media screen and (width >= 1100px) {
  .price {
    font-size: 1.8rem;
  }
}

@media screen and (width >= 1300px) {
  .price {
    font-size: 2.1rem;
  }
}

.fd-panel {
  width: 548px;
  padding: 3.5rem;
}

.grid-column-2 {
  width: 100%;
  margin-bottom: 3rem;
}

@media screen and (width >= 52rem) {
  .grid-column-2 {
    width: 46%;
  }
}

.grid-column-3 {
  width: 100%;
}

@media screen and (width >= 52rem) {
  .grid-column-3 {
    width: 46%;
  }
}

@media screen and (width >= 72rem) {
  .grid-column-3 {
    width: 32%;
  }
}

.square-md {
  width: 64px;
  height: 64px;
}

.square-sm {
  width: 36px;
  height: 36px;
}

.square-xs {
  width: 24px;
  height: 24px;
}

.gallery {
  background: #fff;
}

.gallery-cell {
  width: 66%;
  max-width: 800px;
  height: 451px;
}

.gallery-cell img {
  border-radius: 16px;
  max-width: 800px;
}

.gallery-cell:not(.is-selected) {
  opacity: .3;
  transform: scale(.8);
}

.p-line {
  padding-left: 2rem;
  position: relative;
}

.p-line:before {
  content: "";
  background: #0085da;
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
}

.p-line.p-line-crm:before {
  background: #ff5a5f;
}

.p-line.p-line-personalistika:before {
  background: #2766ff;
}

.p-line.p-line-rizeni-majetku:before {
  background: #16db93;
}

.p-line.p-line-firemni-provoz:before {
  background: #23b5d3;
}

.p-line.p-line-sprava-dokumentu:before {
  background: #fd6a40;
}

.carier-card {
  min-width: 320px;
}

@media screen and (width >= 40rem) {
  .carier-card {
    min-width: 400px;
  }
}

@media screen and (width >= 72rem) {
  .carier-card {
    min-width: 500px;
  }
}

.carier-card:hover {
  opacity: .7;
}

.bg-1 {
  background-color: #f2f8ff;
}

@media screen and (width >= 40rem) {
  .bg-1-image {
    background-image: url("bg-1.bcc8b026.png");
    background-position: 50% 65%;
    background-repeat: no-repeat;
  }
}

.bg-2 {
  background-image: url("bg-2.0585136f.png");
  background-position: center;
  background-repeat: no-repeat;
}

.bg-4 {
  bottom: 0;
  left: -21rem;
}

.bg-5 {
  top: -1rem;
  right: 6rem;
}

.bg-7 {
  width: 41.66%;
  max-width: 800px;
  top: 2rem;
  left: -24rem;
}

.bg-8 {
  width: 33%;
  top: 4rem;
  right: -16rem;
}

.bg-9 {
  z-index: -1;
  top: -20rem;
  right: -33rem;
}

.bg-10 {
  z-index: -1;
  top: -4rem;
  left: 50%;
  transform: translateX(-50%);
}

.bg-11 {
  z-index: -1;
  top: 10rem;
  left: -33rem;
}

.bg-main-left {
  display: none;
  top: 4rem;
}

@media screen and (width >= 1380px) {
  .bg-main-left {
    display: block;
    left: -44rem;
  }
}

@media screen and (width >= 1600px) {
  .bg-main-left {
    top: 4rem;
    left: -36rem;
  }
}

.bg-main-right {
  display: none;
  top: 5rem;
}

@media screen and (width >= 1380px) {
  .bg-main-right {
    display: block;
    right: -25rem;
  }
}

@media screen and (width >= 1600px) {
  .bg-main-right {
    top: 5rem;
    right: -17rem;
  }
}

.bg-gray {
  background-color: #f8f8fd;
}

.bg-gradient {
  background: linear-gradient(#fff 55%, #f8f8fd 50%);
}

.bg-dots-1 {
  right: 60px;
}

.pricing .bg-dots-1 {
  top: 120px;
  right: -60px;
}

.bg-dots-2 {
  left: -190px;
}

.bg-dots-3 {
  top: -5rem;
  left: -7rem;
}

.bg-case-studies {
  top: -59px;
  right: -128px;
}

.bg-sky-light {
  background: var(--success-0);
}

.bg-sky {
  background: var(--success-1);
}

.bg-sky-linear {
  background: linear-gradient(0deg, var(--success-2) 50%, var(--success-1) 50%);
}

.bg-sky-linear-alt {
  background: url("webinar-bg-right.c42bb30b.png") right top no-repeat, linear-gradient(283.65deg, var(--success-6) 0%, var(--success-4) 100%);
}

.bg-dark {
  background: var(--success-9);
}

.square {
  background: #f8f9fa;
  border-radius: 12px;
  width: 72px;
  height: 72px;
}

.square.square-lg {
  width: 109px;
  height: 109px;
}

.square-blue {
  background: #f2f8ff;
}

.square-orange {
  background: #fef3ee;
}

.square-mr {
  margin-right: .75rem;
}

.square-mb {
  margin-bottom: .75rem;
}
/*# sourceMappingURL=style.css.map */
