.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

.t0 {
    top: 0;
}

.b0 {
    bottom: 0;
}

.l0 {
    left: 0;
}

.l5 {
    left: 5rem;
}

.l10 {
    left: 10rem;
}

.r0 {
    right: 0;
}

.r5 {
    right: 5rem;
}

.t15m {
    top: -15%;
}

.t20m {
    top: -20%;
}

.t30m {
    top: -30%;
}

.t35m {
    top: -35%;
}

.t40m {
    top: -40%;
}

.t50m {
    top: -50%;
}

.t70m {
    top: -70%;
}

.l50 {
    left: 50%;
}