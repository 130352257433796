@import './utils-variables.less';

.cols() {
    1: 8.33333%;
    2: 16.66667%;
    3: 25%;
    4: 33.33333%;
    5: 41.66667%;
    6: 50%;
    7: 58.33333%;
    8: 66.66667%;
    9: 75%;
    10: 83.33333%;
    11: 91.66667%;
    12: 100%;
}

each(.cols(), .(@v, @k, @i) {
        .col-@{k} {
            width: @v;
        }

    }

);

@media screen and (min-width: @screen-sm-min) {

    each(.cols(), .(@v, @k, @i) {
            .col-sm-@{k} {
                width: @v;
            }

        }

    );
}

@media screen and (min-width: @screen-md-min) {

    each(.cols(), .(@v, @k, @i) {
            .col-md-@{k} {
                width: @v;
            }

        }

    );
}

@media screen and (min-width: @screen-lg-min) {

    each(.cols(), .(@v, @k, @i) {
            .col-lg-@{k} {
                width: @v;
            }

        }

    );
}

@media screen and (min-width: @screen-xlg-min) {

    each(.cols(), .(@v, @k, @i) {
            .col-xlg-@{k} {
                width: @v;
            }

        }

    );
}
