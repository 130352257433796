@import '../variables.less';

// backgrounds images

.bg-1 {
    background-color: @default-1;
}

.bg-1-image {
    @media screen and (min-width: @screen-sm-min) {
        background-image: url(../../img/bg/bg-1.png);
        background-repeat: no-repeat;
        background-position: center 65%;
    }
}

.bg-2 {
    background-image: url(../../img/bg/bg-2.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-4 {
    bottom: 0;
    left: -21rem;
}

.bg-5 {
    right: 6rem;
    top: -1rem;
}

.bg-7 {
    left: -24rem;
    top: 2rem;
    width: 41.66%;
    max-width: 800px;
}

.bg-8 {
    top: 4rem;
    right: -16rem;
    width: 33%;
}

.bg-9 {
    top: -20rem;
    right: -33rem;
    z-index: -1;
}

.bg-10 {
    top: -4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.bg-11 {
    top: 10rem;
    left: -33rem;
    z-index: -1;
}

.bg-main-left {
    display: none;
    top: 4rem;

    @media screen and (min-width: 1380px) {
        display: block;
        left: -44rem;
    }

    @media screen and (min-width: 1600px) {
        left: -36rem;
        top: 4rem;
    }
}

.bg-main-right {
    display: none;
    top: 5rem;    

    @media screen and (min-width: 1380px) {
        display: block;
        right: -25rem;
    }

    @media screen and (min-width: 1600px) {
        right: -17rem;
        top: 5rem;
    }
}

.bg-gray {
    background-color: @default-2;
}

.bg-gradient {
    background: @bg-gradient;
}

.bg-dots-1 {
    right: 60px;
}

.pricing .bg-dots-1 {
    right: -60px;
    top: 120px;
}

.bg-dots-2 {
    left: -190px;
}

.bg-dots-3 {
    left: -7rem;
    top: -5rem;
}

.bg-case-studies {
    right: -128px;
    top: -59px;
}

.bg-sky-light {
    background: var(--success-0);
}

.bg-sky {
    background: var(--success-1);
}

.bg-sky-linear {
    background: linear-gradient(0deg, var(--success-2) 50%, var(--success-1) 50%);
}

.bg-sky-linear-alt {
    background: url(../../img/landings/webinar/webinar-bg-right.png) right top no-repeat, linear-gradient(283.65deg, var(--success-6) 0%, var(--success-4) 100%);    
}

.bg-dark {
    background: var(--success-9);
}