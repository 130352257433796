@import '../variables.less';

.fading {
    animation: fading 2.5s infinite;
}

.p-box {
    animation: fading 2.5s infinite;
    background: @default-3;
    border-radius: 8px;
    display: inline-block;
    font-size: 0;
    height: 12px;
    line-height: 0;
    width: 100%;
}

.p-box-lg {
    height: 80px;
}

@keyframes fading {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .6;
    }

    100% {
        opacity: 1;
    }
}