@media screen and (max-width: @screen-xs-max) {
    .hide-xs {
        display: none !important;
    }
}

@media screen and (max-width: @screen-sm-max) {
    .hide-sm {
        display: none !important;
    }
}

@media screen and (max-width: @screen-md-max) {
    .hide-md {
        display: none !important;
    }
}

.hide.visible-xs {
    @media screen and (max-width: @screen-xs-max) {
        display: block !important;
    }
}

.hide.visible-sm {
    @media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        display: block !important;
    }
}

.d-block {
    display: block;
}

.d-block-lg {
    display: none;
}

@media screen and (min-width: @screen-lg-min) {
    .d-block-lg {
        display: block;
    }
}

.hide {
    display: none !important;
}