.flex-auto {
    flex: auto;
}

.flex {
    display: flex;
}

@media screen and (min-width: @screen-lg-min) {
    .flex-md {
        display: flex;
    }
}

@media screen and (max-width: @screen-xs-max) {
    .flex-column-reverse-xs {
        flex-direction: column-reverse;
    }

    .flex-column-xs {
        flex-direction: column;
    }
}

.flex-column {
    .flex;
    .flex-direction-column();
}

.flex-1 {
    .flex(1);
}

.flex-2 {
    .flex(2);
}

.flex(@number) {
    flex: @number;
}

.flex-direction-column {
    .flex-direction(column);
}

.flex-direction(@value) {
    flex-direction: @value;
}

.flex-vertical-center {
    align-items: center;
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;       
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-end {
    align-items: flex-end;
}

.items-start {
    align-items: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-evently {
    justify-content: space-evenly;
}

.justify-end {
    justify-content: flex-end;
}

@media screen and (min-width: @screen-lg-min) {
    .justify-end-lg {
        justify-content: flex-end;
    }
}


.justify-center-xs {
    @media screen and (max-width: @screen-xs-max) {
        justify-content: center !important;
    }
}

.justify-center-sm {
    @media screen and (max-width: @screen-sm-max) {
        justify-content: center !important;
    }
}