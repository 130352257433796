@import '../variables.less';

.color-white {
    color: @white;
}

.white {
    color: @white !important;
}

.success-2 {
    color: var(--success-2);
}

.success-4 {
    color: var(--success-4);
}

.success-9 {
    color: var(--success-9);
}

.color-lavender {
    color: @lavender;
}

.bg-white {
    background-color: @white;
}

.bg-dark {
    background-color: @color-dark;
}

.color-dark {
    color: @color-dark;
}

.bg-dark-lighter {
    background-color: @color-dark-lighter;
}



.bg-dark-lighter-white {
    background: linear-gradient(@color-dark-lighter 50%, #fff 50%);
}

.color-success {
    color: var(--success);
}

.primary {
    color: var(--primary);
}


.bg-success-alt {
    background: var(--success-alt);
}

.tbd-success {
    color: @tbd-success-5;
}
.tbd-primary {
    color: @tbd-primary-5 !important;
}
.tbd-danger {
    color: @tbd-danger-5 !important;
}
.tbd-warning {
    color: @tbd-warning-5;
}

.tbd-green {
    color: #00ca9a !important; 
}

.color-make {
    color: @color-make;
}

.bg-success {
    background: var(--success);
}

.color-crm {
    color: @color-crm;
}

.color-personalistika {
    color: @color-personalistika;
}

.color-rizeni-majetku {
    color: @color-rizeni-majetku;
}

.color-firemni-provoz {
    color: @color-firemni-provoz;
}

.color-sprava-dokumentu {
    color: @color-sprava-dokumentu;
}

.bg-crm {
    background: @color-crm;
}
.bg-crm-alt {
    background: @color-crm-alt;
}

.bg-personalistika {
    background: @color-personalistika;
}
.bg-personalistika-alt {
    background: @color-personalistika-alt;
}

.bg-rizeni-majetku {
    background: @color-rizeni-majetku;
}
.bg-rizeni-majetku-alt {
    background: @color-rizeni-majetku-alt;
}

.bg-firemni-provoz {
    background: @color-firemni-provoz;
}
.bg-firemni-provoz-alt {
    background: @color-firemni-provoz-alt;
}

.bg-sprava-dokumentu {
    background: @color-sprava-dokumentu;
}
.bg-sprava-dokumentu-alt {
    background: @color-sprava-dokumentu-alt;
}