.testi {
    display: flex;

    .quotemark {
        position: absolute;
    }

    .quote-left {
        left: -32px;
    }

    .quote-right {
        margin-left: 0.5rem;
        padding-top: 0.5rem;
    }
}

.testi-left {
    margin-left: 0.75rem;    
}

.testi-logo, .testi-face {
    display: block;
}

.testi-face {
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    object-fit: cover;
    height: 154px;
    width: 154px;
}

.testi-logo {
    display: block;    
    max-width: 154px;
}

.testi-panel {
    margin-left: 12px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 2rem 3.5rem;
    max-width: 573px;
}

.testi-quote {
    position: relative;
    font-size: 19px;
    margin-bottom: 2rem;
}

.testi-footer {
    display: flex;
    align-items: center;
}

.testi-name {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 1.5rem;
}

.testi-position {
    opacity: 0.8;
    margin-right: 1.5rem;
}

.testi.testi-success {
    .testi-panel {
        background: @success;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .testi-quote,
    .testi-footer {
        color: #fff;
    }

    .link {
        color: #fff;
    }
}

.testi.testi-big {

    .testi-face {
        height: 202px;
        width: 202px;
    }

    .testi-logo {
        max-width: 202px;
    }

    .testi-quote {
        font-size: 24px;
    }

    .testi-panel {
        max-width: 700px;
        margin-left: 2.5rem;
    }
}