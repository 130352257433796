@import './utils-variables.less';

.spacingType() {
    m: margin;
    p: padding;
}

.direction() {
    l: left;
    t: top;
    b: bottom;
    r: right;
}

.axes() {
    x: x;
    y: y;
}

.space() {
    0: 0;
    1: 0.25;
    2: 0.5;
    3: 1;
    4: 2;
    5: 4;
    6: 6;
}

// result
// .m0 { margin: 0rem; }
// .p2 { padding: 0.5rem; }

each(.space(), .(@v, @k, @i) {
        each(.spacingType(), .(@vt, @kt, @it) {
                .@{kt}@{k} {
                    @{vt}: @spacer * @v  !important;
                }
            }

        )
    }

);

// result
// .mx0 { margin-left: 0rem; margin-right: 0rem; }
// .py2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }

each(.space(), .(@v, @k, @i) {
        each(.spacingType(), .(@vt, @kt, @it) {
                .@{kt}y@{k} {
                    @{vt}-top: @spacer * @v !important;
                    @{vt}-bottom: @spacer * @v !important;
                }

                .@{kt}x@{k} {
                    @{vt}-left: @spacer * @v !important;
                    @{vt}-right: @spacer * @v !important;
                }
            }

        )
    }

);

// result
// .mt0 { margin-top: 0rem; }
// .pb2 { padding-bottom: 0.5rem; }

each(.space(), .(@v, @k, @i) {
        each(.direction(), .(@vd, @kd, @id) {
                each(.spacingType(), .(@vt, @kt, @it) {
                        .@{kt}@{kd}@{k} {
                            @{vt}-@{vd}: @spacer * @v  !important;
                        }
                    }

                )
            }

        )
    }

);

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: @screen-lg-min) {
    .mt5-lg {
        margin-top: @spacer * 4 !important;
    }    

    .ml5-lg {
        margin-left: @spacer * 4 !important;
    }
}

@media screen and (min-width: @screen-sm-min) {
    .mt5-sm {
        margin-top: @spacer * 4 !important;
    }    

    .px5-sm {
        padding-left: @spacer * 5 !important;
        padding-right: @spacer * 5 !important;
    }
}

@media screen and (max-width: @screen-xs-max) {
    .pb5-xs {
        padding-bottom: @spacer * 4 !important;
    }

    .mr3-xs {
        margin-right: @spacer !important;
    }

    .py5 {
        padding-bottom: @spacer * 2 !important;
        padding-top: @spacer * 2 !important;        
    }

    .my5 {
        margin-bottom: @spacer * 2 !important;
        margin-top: @spacer * 2 !important;        
    }

    .mt5 {
        margin-top: @spacer * 2 !important;       
    }

    .mb5 {
        margin-bottom: @spacer * 2 !important;       
    }

    .pt5 {
        padding-top: @spacer * 2 !important;
    }

    .pb5 {
        padding-bottom: @spacer * 2 !important;
    }
}



.ml-3 {
    margin-left: -1rem;
}