@import '../variables.less';

.btn {
    align-items: center;
    border: none;
    border-radius: @br-btn;
    display: inline-flex;
    font-size: 14.5px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 0.01rem;
    padding: 0.25rem 1rem;
    position: relative;

    @media screen and (min-width: @screen-sm-min) {
        padding: 0.25rem 1.5rem;
    }

    @media screen and (min-width: @screen-md-min) {
        font-size: 15px; 
    }
    @media screen and (min-width: @screen-lg-min) {
        font-size: 16px; 
    }
    @media screen and (min-width: @screen-xlg-min) {
        font-size: 18px;
    }

    &.btn-sm {
        font-size: @font-sm;
        padding: 0rem 1.5rem;

        &.btn-hamburger {
            padding: 0.5rem;
        }
    }    

    &.btn-lg {
        border-radius: 0.75rem;
        padding: 0.5rem 1.75rem;
    }    
}

.btn.btn-arrow {

    padding-right: 2.5rem;

    @media screen and (min-width: @screen-sm-min) {
        padding-right: 3.2rem;
    }

    &:after {
        position: absolute;
        content: '';
        background-image: url(../../img/svg/angle-right.svg);
        background-repeat: no-repeat;
        height: 1.7rem;
        width: 1rem;
        right: 0.5rem;
        top: 52%;
        transform: translateY(-50%);

        @media screen and (min-width: @screen-sm-min) {
            right: 1rem;
        }
    }
}

.btn.btn-link {
    color: @success;

    &:hover {
        opacity: 0.9;
    }
}

.btn.btn-link.btn-arrow {
    &:after {
        background-image: url(../../img/svg/angle-right-blue.svg);
    }
}

.btn-default, .btn-default-alt {
    border: 1px solid var(--success);
    color: var(--success);

    &:hover {
        background: @btn-default-bg-hover;
        border-color: transparent;
    }
}

.btn-default-alt {
    background: @white;
    border-color: transparent;

    &:hover {
        background: @white;
        opacity: 0.7;
    }
}

.btn-tbd-success {
    color: #fff;
    background: @tbd-success-5;

    &:hover {
        background: @tbd-success-6;
    }
}

.btn-tbd-danger {
    color: #fff;
    background: @tbd-danger-5;

    &:hover {
        background: @tbd-danger-6;
    }
}

.btn-tbd-primary {
    color: #fff;
    background: @tbd-primary-5;

    &:hover {
        background: @tbd-primary-6;
    }
}

.btn-tbd-warning {
    color: #fff;
    background: @tbd-warning-5;

    &:hover {
        background: @tbd-warning-6;
    }
}

.btn-success {
    color: var(--color-btn-success-on);
    background: var(--color-btn-success);

    &:hover {
        background: var(--color-btn-success-hover);
    }

    &:active, &:focus {
        background: var(--success-6);
    }
}

.btn-success-alt {
    color: var(--success);
    background: var(--success-0);

    &:hover {
        background: var(--success-1);
    }

    &:active, &:focus {
        background: var(--success-2);
    }
}

.btn-primary {
    color: #fff;
    background: var(--primary);

    &:hover {
        background: var(--primary-4);
    }

    &:active, &:focus {
        background: var(--primary-6);
    }
}

// @color-crm: #FF5A5F;
// @color-crm-alt: #FFF5F6;
// @color-personalistika: #2766FF;
// @color-personalistika-alt: #F1F4FF;
// @color-rizeni-majetku: @color-success-alt-5;
// @color-rizeni-majetku-alt: @color-success-alt-1;
// @color-firemni-provoz: #23B5D3;
// @color-firemni-provoz-alt: #e8f8fb;
// @color-sprava-dokumentu: #FD6A40;
// @color-sprava-dokumentu-alt: #fff7f5;

.btn-crm, .btn-personalistika, .btn-rizeni-majetku, .btn-firemni-provoz, .btn-sprava-dokumentu {
    color: #fff;
    &:hover {
        opacity: 0.8;
    }
}

.btn-crm {    
    background: @color-crm;
}
.btn-personalistika {
    background: @color-personalistika;
}
.btn-rizeni-majetku {
    background: @color-rizeni-majetku;
}
.btn-firemni-provoz {
    background: @color-firemni-provoz;
}
.btn-sprava-dokumentu {
    background: @color-sprava-dokumentu;
}

.btn-secondary {
    color: @btn-secondary-fg;
    background: @btn-secondary-bg;

    &:hover {
        background: @btn-secondary-bg-hover;
    }
}

.btn-hamburger {
    @media screen and (min-width: @screen-lg-min) {
        display: none;
    }
}

.hamburger {

    width: 20px;
    height: 2px;
    background-color: var(--success);
    position: relative;
    transition: .085s all ease-in;

    &:before {
        content: '';
        position: absolute;
        top: -6px;
        width: 20px;
        height: 2px;
        background-color: var(--success);
        transition: .085s all ease-in;
    }

    &:after {
        content: '';
        position: absolute;
        top: 6px;
        width: 20px;
        height: 2px;
        background-color: var(--success);
        transition: .085s all ease-in;
    }

    &.open {
        background-color: transparent;

        &:after,
        &:before {
            top: 0;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}