.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}